import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import {
  ONBOARD,
  CONSENT_DOCS,
  ASSETS,
  DEBT,
  ACCOUNTS,
  DISSOLUTION_STAGE,
  INVESTORS,
  STATES,
  TAXES,
} from "../utils/constants";

export const HOME_PATH = '/overview';
export const ORG_DRAWER_PATH = `${HOME_PATH}/full`;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}



export const stageColors = {
  [ONBOARD]: '#10B981',
  [CONSENT_DOCS]: '#3B82F6',
  [ASSETS]: '#F59E0B',
  [DEBT]: '#EF4444',
  [ACCOUNTS]: '#06B6D4',
  [DISSOLUTION_STAGE]: '#8B5CF6',
  [INVESTORS]: '#EC4899',
  [STATES]: '#F97316',
  [TAXES]: '#10B981',
};
