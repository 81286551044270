import constantsJson from './constants.json';
import type { Constants } from '../types/constants';

export const constants: Constants = constantsJson;

export const ONBOARD = constants.ONBOARD;
export const CONSENT_DOCS = constants.CONSENT_DOCS;
export const ASSETS = constants.ASSETS;
export const DEBT = constants.DEBT;
export const ACCOUNTS = constants.ACCOUNTS;
export const DISSOLUTION_STAGE = constants.DISSOLUTION_STAGE;
export const INVESTORS = constants.INVESTORS;
export const STATES = constants.STATES;
export const TAXES = constants.TAXES;
export const INTAKE_FORM = constants.INTAKE_FORM;
export const SEND_INTAKE_FORM = constants.SEND_INTAKE_FORM;
export const COMPLETE_INTAKE_FORM = constants.COMPLETE_INTAKE_FORM;
export const KICKED_OFF_TO_CLIENT = constants.KICKED_OFF_TO_CLIENT;
export const CLIENT_REVIEWING_KICKOFF = constants.CLIENT_REVIEWING_KICKOFF;
export const DISSOLUTION_PLAN = constants.DISSOLUTION_PLAN;
export const GENERATE_PLAN = constants.GENERATE_PLAN;
export const LEGAL_REVIEWED_PLAN = constants.LEGAL_REVIEWED_PLAN;
export const CONSENT_DOCUMENTS = constants.CONSENT_DOCUMENTS;
export const SEND_CONSENT_DOCUMENTS = constants.SEND_CONSENT_DOCUMENTS;
export const SIGN_CONSENT_DOCUMENTS = constants.SIGN_CONSENT_DOCUMENTS;
export const ASSET_DECISION = constants.ASSET_DECISION;
export const SEND_ASSET_DECISION_EMAIL = constants.SEND_ASSET_DECISION_EMAIL;
export const CLIENT_REVIEWING_ASSET_DECISION = constants.CLIENT_REVIEWING_ASSET_DECISION;
export const ENTER_ASSET_DECISION = constants.ENTER_ASSET_DECISION;
export const SEND_ASSET_INVENTORY_SPREADSHEET = constants.SEND_ASSET_INVENTORY_SPREADSHEET;
export const ENTER_ASSET_INVENTORY = constants.ENTER_ASSET_INVENTORY;
export const PREPARE_AUCTION = constants.PREPARE_AUCTION;
export const SEND_AUCTION_TO_CLIENT_FOR_REVIEW = constants.SEND_AUCTION_TO_CLIENT_FOR_REVIEW;
export const APPROVE_AUCTION = constants.APPROVE_AUCTION;
export const LAUNCH_AUCTION = constants.LAUNCH_AUCTION;
export const CONCLUDE_AUCTION = constants.CONCLUDE_AUCTION;
export const NOTIFY_AUCTION_BUYERS = constants.NOTIFY_AUCTION_BUYERS;
export const DISTRIBUTE_IP_PRO_RATA_PLACEHOLDER = constants.DISTRIBUTE_IP_PRO_RATA_PLACEHOLDER;
export const OPEN_SOURCE_IP_PLACEHOLDER = constants.OPEN_SOURCE_IP_PLACEHOLDER;
export const COLLECT_IP_SALES_AGREEMENT = constants.COLLECT_IP_SALES_AGREEMENT;
export const LEGAL_REVIEW_IP_SALES_AGREEMENT = constants.LEGAL_REVIEW_IP_SALES_AGREEMENT;
export const GATHER_IP_ASSIGNMENT_INVENTORY = constants.GATHER_IP_ASSIGNMENT_INVENTORY;
export const PREPARE_IP_ASSIGNMENT_AGREEMENT = constants.PREPARE_IP_ASSIGNMENT_AGREEMENT;
export const LEGAL_REVIEW_IP_ASSIGNMENT_AGREEMENT = constants.LEGAL_REVIEW_IP_ASSIGNMENT_AGREEMENT;
export const SEND_IP_ASSIGNMENT_AGREEMENT_TO_CLIENT = constants.SEND_IP_ASSIGNMENT_AGREEMENT_TO_CLIENT;
export const SIGN_IP_ASSIGNMENT_AGREEMENT = constants.SIGN_IP_ASSIGNMENT_AGREEMENT;
export const HANDLE_DEBT_OBLIGATIONS = constants.HANDLE_DEBT_OBLIGATIONS;
export const ENTER_DEBT_SCHEDULE = constants.ENTER_DEBT_SCHEDULE;
export const RESOLVE_DEBT_OBLIGATIONS = constants.RESOLVE_DEBT_OBLIGATIONS;
export const CLOSE_ACCOUNTS = constants.CLOSE_ACCOUNTS;
export const NOTIFY_IRS = constants.NOTIFY_IRS;
export const SEND_FORM_966 = constants.SEND_FORM_966;
export const SIGN_FORM_966 = constants.SIGN_FORM_966;
export const FILE_FORM_966 = constants.FILE_FORM_966;
export const NOTIFY_DELAWARE = constants.NOTIFY_DELAWARE;
export const GENERATE_REVIVAL_DOCUMENT = constants.GENERATE_REVIVAL_DOCUMENT;
export const SEND_REVIVAL_DOCUMENT_TO_CLIENT = constants.SEND_REVIVAL_DOCUMENT_TO_CLIENT;
export const SIGN_REVIVAL_DOCUMENT = constants.SIGN_REVIVAL_DOCUMENT;
export const NOTIFY_CSC_OF_REVIVAL = constants.NOTIFY_CSC_OF_REVIVAL;
export const CSC_REVIEWING_REVIVAL = constants.CSC_REVIEWING_REVIVAL;
export const CSC_CONFIRMED_REVIVAL = constants.CSC_CONFIRMED_REVIVAL;
export const PAY_DE_FRANCHISE_TAXES = constants.PAY_DE_FRANCHISE_TAXES;
export const FILE_CERTIFICATE_OF_DISSOLUTION = constants.FILE_CERTIFICATE_OF_DISSOLUTION;
export const DELAWARE_PROCESSING_CERTIFICATE_OF_DISSOLUTION = constants.DELAWARE_PROCESSING_CERTIFICATE_OF_DISSOLUTION;
export const MARK_CERTIFICATE_OF_DISSOLUTION_RECEIVED = constants.MARK_CERTIFICATE_OF_DISSOLUTION_RECEIVED;
export const INVESTOR_REDISTRIBUTION = constants.INVESTOR_REDISTRIBUTION;
export const CONFIRM_CASH_ON_HAND = constants.CONFIRM_CASH_ON_HAND;
export const SEND_NOTICE_CONFIRMATION_EMAIL = constants.SEND_NOTICE_CONFIRMATION_EMAIL;
export const CONFIRM_NOTICE_CONFIRMATION_EMAIL = constants.CONFIRM_NOTICE_CONFIRMATION_EMAIL;
export const GENERATE_INVESTOR_NOTICES = constants.GENERATE_INVESTOR_NOTICES;
export const SEND_INVESTOR_NOTICES = constants.SEND_INVESTOR_NOTICES;
export const ENTER_WIRING_DETAILS = constants.ENTER_WIRING_DETAILS;
export const REDISTRIBUTE_CAPITAL_TO_INVESTORS = constants.REDISTRIBUTE_CAPITAL_TO_INVESTORS;
export const STATE_WITHDRAWLS = constants.STATE_WITHDRAWLS;
export const FINAL_YEAR_TAXES_COMPLETED = constants.FINAL_YEAR_TAXES_COMPLETED;
export const WAITING_ON_RIVET_FINAL_YEAR_TAXES = constants.WAITING_ON_RIVET_FINAL_YEAR_TAXES;
export const ALABAMA = constants.ALABAMA;
export const ALASKA = constants.ALASKA;
export const ARIZONA = constants.ARIZONA;
export const ARKANSAS = constants.ARKANSAS;
export const CALIFORNIA = constants.CALIFORNIA;
export const COLORADO = constants.COLORADO;
export const CONNECTICUT = constants.CONNECTICUT;
export const DELAWARE = constants.DELAWARE;
export const DISTRICT_OF_COLUMBIA = constants.DISTRICT_OF_COLUMBIA;
export const FLORIDA = constants.FLORIDA;
export const GEORGIA = constants.GEORGIA;
export const HAWAII = constants.HAWAII;
export const IDAHO = constants.IDAHO;
export const ILLINOIS = constants.ILLINOIS;
export const INDIANA = constants.INDIANA;
export const IOWA = constants.IOWA;
export const KANSAS = constants.KANSAS;
export const KENTUCKY = constants.KENTUCKY;
export const LOUISIANA = constants.LOUISIANA;
export const MAINE = constants.MAINE;
export const MARYLAND = constants.MARYLAND;
export const MASSACHUSETTS = constants.MASSACHUSETTS;
export const MICHIGAN = constants.MICHIGAN;
export const MINNESOTA = constants.MINNESOTA;
export const MISSISSIPPI = constants.MISSISSIPPI;
export const MISSOURI = constants.MISSOURI;
export const MONTANA = constants.MONTANA;
export const NEBRASKA = constants.NEBRASKA;
export const NEVADA = constants.NEVADA;
export const NEW_HAMPSHIRE = constants.NEW_HAMPSHIRE;
export const NEW_JERSEY = constants.NEW_JERSEY;
export const NEW_MEXICO = constants.NEW_MEXICO;
export const NEW_YORK = constants.NEW_YORK;
export const NORTH_CAROLINA = constants.NORTH_CAROLINA;
export const NORTH_DAKOTA = constants.NORTH_DAKOTA;
export const OHIO = constants.OHIO;
export const OKLAHOMA = constants.OKLAHOMA;
export const OREGON = constants.OREGON;
export const PENNSYLVANIA = constants.PENNSYLVANIA;
export const RHODE_ISLAND = constants.RHODE_ISLAND;
export const SOUTH_CAROLINA = constants.SOUTH_CAROLINA;
export const SOUTH_DAKOTA = constants.SOUTH_DAKOTA;
export const TENNESSEE = constants.TENNESSEE;
export const TEXAS = constants.TEXAS;
export const UTAH = constants.UTAH;
export const VERMONT = constants.VERMONT;
export const VIRGINIA = constants.VIRGINIA;
export const WASHINGTON = constants.WASHINGTON;
export const WEST_VIRGINIA = constants.WEST_VIRGINIA;
export const WISCONSIN = constants.WISCONSIN;
export const WYOMING = constants.WYOMING;
export const SAN_FRANCISCO = constants.SAN_FRANCISCO;

export default constants;
